import { ref, markRaw } from "@vue/composition-api";
import humps from "humps";
import qs from "qs";
import useLazyImport from "@/composable/useLazyImport";
import useReport from "@/composable/useReport";

let isCloseTransitionFinish = ref(false);
let isOpenTransitionFinish = ref(false);
let isOpen = ref(false);
const isComponentLoaded = ref(false);
const bottomSheetComponent = ref();

function addBottomSheetMarkToURL() {
  const parsedQueryString = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  parsedQueryString.bottomSheet = true;
  const stringify = qs.stringify(humps.decamelizeKeys(parsedQueryString));

  window.history.pushState(null, null, `?${stringify}`);
}

function removeBottomSheetMarkFromURL() {
  const parsedQueryString = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  parsedQueryString.bottomSheet = null;
  const stringify = qs.stringify(humps.decamelizeKeys(parsedQueryString), {
    skipNulls: true,
  });

  window.history.replaceState(null, null, `?${stringify}`);
}

async function loadBottomSheetComponent() {
  try {
    const module = await useLazyImport(() =>
      import(
        /* webpackChunkName: "BottomSheetChunk" */ "@/components/Common/BottomSheet/BottomSheet.vue"
      )
    );
    if (module?.default) {
      bottomSheetComponent.value = markRaw(module.default);
      isComponentLoaded.value = true;
      return;
    }
    isComponentLoaded.value = false;
  } catch (err) {
    isComponentLoaded.value = false;
    useReport({
      level: "error",
      message:
        "Oops, something went wrong, failed to load bottom sheet component",
      errorException: err,
    });
  }
}

export {
  isOpen,
  isCloseTransitionFinish,
  isOpenTransitionFinish,
  addBottomSheetMarkToURL,
  removeBottomSheetMarkFromURL,
  isComponentLoaded,
  loadBottomSheetComponent,
  bottomSheetComponent,
};
