import Vue from "vue";
import VueRouter from "vue-router";
import * as alert from "@/lib/alert";
import qs from "qs";
import useLazyImport from "@/composable/useLazyImport";

const RestaurantPage = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "restaurant" */ "../views/RestaurantPageLoader.vue"
    )
  );
const HomePage = () =>
  useLazyImport(
    /* webpackChunkName: "restaurant" */ () => import("../views/HomePage.vue")
  );
const BookingPage = () =>
  useLazyImport(() =>
    import(/* webpackChunkName: "restaurant" */ "../views/BookingPage.vue")
  );
const LandingPage = () =>
  useLazyImport(() =>
    import(/* webpackChunkName: "restaurant" */ "../views/LandingPage.vue")
  );
const VoucherLandingPage = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "restaurant" */ "../views/VoucherLandingPage.vue"
    )
  );
const VoucherQRPaymentLandingPage = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "restaurant" */ "../views/VoucherQRPaymentPage.vue"
    )
  );
const SearchPage = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "restaurant" */ "../views/SearchPagePegasus.vue"
    )
  );
const ProfilePage = () =>
  useLazyImport(() =>
    import(/* webpackChunkName: "restaurant" */ "../views/ProfilePage.vue")
  );
const TopRestaurantsPage = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "restaurant" */ "../views/TopRestaurantsPage.vue"
    )
  );
const VoucherPage = () =>
  useLazyImport(() =>
    import(/* webpackChunkName: "restaurant" */ "../views/VoucherPage.vue")
  );
const BuyVoucherPage = () =>
  useLazyImport(() => import("../views/BuyVoucherPage.vue"));
const BuyPackTicketPage = () =>
  useLazyImport(() => import("../views/BuyPackTicketPage.vue"));
const PackageTicketLandingPage = () =>
  useLazyImport(() => import("../views/PackageTicketLandingPage.vue"));
const PackTicketQRPaymentLandingPage = () =>
  useLazyImport(() => import("../views/PackageTicketQRPaymentLandingPage.vue"));
const PackTicketPage = () =>
  useLazyImport(() => import("../views/PackageTicketPage.vue"));
const PackageTicketTransactionPage = () =>
  useLazyImport(() => import("../views/PackageTicketTransactionPage.vue"));
const TopBrandPage = () =>
  useLazyImport(() => import("../views/TopBrandPage.vue"));
const PackageMenuPage = () =>
  useLazyImport(() => import("../views/PackageMenuPage.vue"));

import {
  homePageGuard,
  bookingPageGuard,
  buyVoucherPageGuard,
  sidebarBookingGuard,
  mobileBookingGuard,
  profilePageGuard,
  profilePageGuardMobile,
  restaurantPageGuard,
  giftCardPageGuard,
  registerPageGuard,
  flipperGuard,
} from "./routerGuard";
import {
  ROUTE_HOME_PAGE,
  ROUTE_SEARCH_PAGE,
  ROUTE_RESTAURANT_PAGE,
  ROUTE_VOUCHER_PAGE,
  ROUTE_BUY_VOUCHER_PAGE,
  ROUTE_BUY_RESTAURANT_VOUCHER_PAGE,
  ROUTE_BUY_RESTAURANT_VOUCHER_SIDEBAR,
  ROUTE_BUY_PACKAGE_TICKET_SIDEBAR,
  ROUTE_BUY_PACKAGE_TICKET_PAGE,
  ROUTE_BOOKING_LANDING_PAGE,
  ROUTE_VOUCHER_LANDING_PAGE,
  ROUTE_VOUCHER_QR_PAYMENT_LANDING_PAGE,
  ROUTE_PROFILE_VOUCHER,
  ROUTE_PROFILE_OFFERS_GIFT_CARD,
  ROUTE_PROFILE_HOME,
  ROUTE_PROFILE_EDIT,
  ROUTE_PROFILE_PASSWORD,
  ROUTE_PROFFILE_POINT_HISTORY,
  ROUTE_PROFILE_FAVOURITE,
  ROUTE_PROFILE_HISTORY,
  ROUTE_PROFILE_POINT,
  ROUTE_PAYMENT_SUCCESS,
  ROUTE_NOT_FOUND,
  ROUTE_PAYMENT_OTP,
  ROUTE_PAYMENT_FAILED,
  ROUTE_PROFILE_BENEFITS,
  ROUTE_PROFILE_ADDRESS,
  ROUTE_PROFILE_PAYMENT_METHOD,
  ROUTE_GROUP_LANDING_PAGE,
  ROUTE_PACKAGE_TICKET_LANDING_PAGE,
  ROUTE_PACKAGE_TICKET_QR_PAYMENT_LANDING_PAGE,
  ROUTE_PACKAGE_TICKET_PAGE,
  ROUTE_PACKAGE_TICKET_QR_PAGE,
  ROUTE_DOWNLOAD_APP,
  ROUTE_ENVIRONMENT_FLIPPER,
  ROUTE_FLIPPER,
} from "@/lib/constant";
import { screen } from "@/helper/screenSizeHelper";
import rollbar from "@/lib/rollbar";
import { sendEvent } from "@/lib/netcore";

const baseURL = window.location.origin;

const OpenRiceMenuPage = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "OpenRiceMenuPage" */ "@/views/OpenRiceMenuPage.vue"
    )
  );
const BookingStep1 = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "BookingFlow" */ "@/components/Page Component/Booking/BookingStep1.vue"
    )
  );

const BookingStep2 = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "BookingFlow" */ "@/components/Page Component/Booking/BookingStep2.vue"
    )
  );

const BookingStep3 = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "BookingFlow" */ "@/components/Page Component/Booking/BookingStep3.vue"
    )
  );

const BookingStep4 = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "BookingFlow" */ "@/components/Page Component/Booking/BookingStep4.vue"
    )
  );

const BookingStep5 = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "BookingFlow" */ "@/components/Page Component/Booking/BookingStep5.vue"
    )
  );

const GroupLandingPage = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "GroupLandingPage" */ "@/views/GroupLandingPage.vue"
    )
  );

const PromotionPage = () =>
  useLazyImport(() =>
    import(/* webpackChunkName: "PromotionPage" */ "@/views/PromotionPage.vue")
  );

const NotFoundPage = () =>
  useLazyImport(() =>
    import(/* webpackChunkName: "NotFoundPage" */ "@/views/NotFoundPage.vue")
  );

const ProfileHome = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "ProfileMobileChunk" */ "@/components/Page Component/Profile/Home/ProfileHome.vue"
    )
  );

const ProfileEdit = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "ProfileMobileChunk" */ "@/components/Page Component/Profile/Edit/ProfileEditMobile.vue"
    )
  );

const ProfileFavourite = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "ProfileMobileChunk" */ "@/components/Page Component/Profile/Favourite/ProfileFavourite.vue"
    )
  );

const ProfileHistory = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "ProfileMobileChunk" */ "@/components/Page Component/Profile/History/ProfileHistory.vue"
    )
  );

const ProfilePoint = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "ProfileMobileChunk" */ "@/components/Page Component/Profile/Point/ProfilePoint.vue"
    )
  );

const ProfileEditPasswordPage = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "ProfileMobileChunk" */ "@/components/Page Component/Profile/Edit/ProfileEditPasswordPage.vue"
    )
  );

const ProfilePointHistory = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "ProfileMobileChunk" */ "@/components/Page Component/Profile/Point/ProfilePointHistory.vue"
    )
  );

const ProfileMemberPrivilege = () =>
  import(
    /* webpackChunkName: "ProfileMobileChunk" */ "@/components/Page Component/Profile/MemberPrivilege/MemberPrivilege.vue"
  );
const ProfileAddress = () =>
  import(
    /* webpackChunkName: "ProfileMobileChunk" */ "@/components/Page Component/Profile/Address/ProfileAddress.vue"
  );
const ProfilePaymentMethod = () =>
  import(
    /* webpackChunkName: "ProfileMobileChunk" */ "@/components/Page Component/Profile/PaymentMethod/ProfilePaymentMethod.vue"
  );
const ProfileVoucher = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "ProfileMobileChunk" */ "@/components/Page Component/Profile/Voucher/ProfileVoucher.vue"
    )
  );

const ProfileOffers = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "ProfileMobileChunk" */ "@/components/Page Component/Profile/Offers/ProfileOffers.vue"
    )
  );

const PaymentSuccessPage = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "PaymentSuccessPage" */ "@/views/PaymentSuccessPage"
    )
  );

const PaymentOTPPage = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "PaymentSuccessPage" */ "@/views/PaymentOTPPage"
    )
  );

const PaymentFailedPage = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "PaymentFailedPage" */ "@/views/PaymentFailedPage"
    )
  );

const FlipperPage = () =>
  useLazyImport(() =>
    import(/* webpackChunkName: "FlipperPage" */ "@/views/FlipperPage")
  );
const EnvironmentFlipperPage = () =>
  useLazyImport(() =>
    import(
      /* webpackChunkName: "EnvironmentPage" */ "@/components/Page Component/Flipper/EnvironmentPage.vue"
    )
  );

const ErrorPage = () =>
  useLazyImport(() =>
    import(/* webpackChunkName: "Erropage" */ "@/views/ErrorPage.vue")
  );

const DownloadAppPage = () =>
  useLazyImport(() => import("@/views/DownloadAppPage.vue"));

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: ROUTE_HOME_PAGE,
    component: HomePage,
    beforeEnter: homePageGuard,
  },
  {
    path: "/openrice",
    name: "openRicePage",
    component: OpenRiceMenuPage,
    children: [
      {
        path: "select-menu",
        name: "openRiceSelectMenu",
        component: OpenRiceMenuPage,
      },
      {
        path: "view-menu",
        name: "openRiceViewMenu",
        component: OpenRiceMenuPage,
      },
    ],
  },
  {
    path: "/profile",
    alias: "/me",
    component: ProfilePage,
    beforeEnter: profilePageGuard,
    children: [
      {
        path: "",
        name: ROUTE_PROFILE_HOME,
        component: ProfileHome,
      },
      {
        path: "referral",
        name: ROUTE_PROFILE_HOME,
        component: ProfileHome,
      },
      {
        path: "edit",
        name: ROUTE_PROFILE_EDIT,
        component: ProfileEdit,
        beforeEnter: profilePageGuardMobile,
      },
      {
        path: "password",
        name: ROUTE_PROFILE_PASSWORD,
        component: ProfileEditPasswordPage,
        beforeEnter: profilePageGuardMobile,
      },
      {
        path: "favourite",
        name: ROUTE_PROFILE_FAVOURITE,
        component: ProfileFavourite,
      },
      {
        path: "voucher",
        name: ROUTE_PROFILE_VOUCHER,
        component: ProfileVoucher,
      },
      {
        path: "offers-and-gift-card",
        name: ROUTE_PROFILE_OFFERS_GIFT_CARD,
        component: ProfileOffers,
      },
      {
        path: "history",
        name: ROUTE_PROFILE_HISTORY,
        component: ProfileHistory,
      },
      {
        path: "point",
        name: ROUTE_PROFILE_POINT,
        component: ProfilePoint,
      },
      {
        path: "point-history",
        name: ROUTE_PROFFILE_POINT_HISTORY,
        component: ProfilePointHistory,
        beforeEnter: profilePageGuardMobile,
      },
      {
        path: "benefits",
        name: ROUTE_PROFILE_BENEFITS,
        component: ProfileMemberPrivilege,
      },
      {
        path: "address",
        name: ROUTE_PROFILE_ADDRESS,
        component: ProfileAddress,
      },
      {
        path: "payment-method",
        name: ROUTE_PROFILE_PAYMENT_METHOD,
        component: ProfilePaymentMethod,
      },
      {
        path: "*",
        name: "profile-home",
        component: ProfileHome,
      },
    ],
  },
  {
    path: "/promotions",
    name: "promotion",
    component: PromotionPage,
  },
  {
    path: "/hungry-hub-gift-card",
    alias: "/hungry-hub-voucher",
    name: ROUTE_VOUCHER_PAGE,
    beforeEnter: giftCardPageGuard,
    component: VoucherPage,
    meta: { scrollToTop: true },
  },
  {
    path: "/flipper",
    name: ROUTE_FLIPPER,
    beforeEnter: flipperGuard,
    redirect: "flipper/environment",
    component: FlipperPage,
    meta: { scrollToTop: true },
    children: [
      {
        path: "environment",
        name: ROUTE_ENVIRONMENT_FLIPPER,
        component: EnvironmentFlipperPage,
      },
    ],
  },
  {
    path: "/hungry-hub-gift-card/buy",
    alias: "/hungry-hub-voucher/buy",
    name: ROUTE_BUY_VOUCHER_PAGE,
    beforeEnter: giftCardPageGuard,
    component: BuyVoucherPage,
    meta: { scrollToTop: true },
  },
  {
    path: "/restaurants/top",
    name: "top-restaurants",
    component: TopRestaurantsPage,
  },
  {
    path: "/restaurants/search",
    name: ROUTE_SEARCH_PAGE,
    component: SearchPage,
    meta: { scrollToTop: true, discardQueryString: true },
  },
  {
    path: "/restaurants/search.html",
    redirect: {
      name: ROUTE_SEARCH_PAGE,
    },
  },
  {
    path: "/:lang?/voucher_group/:id",
    name: ROUTE_PACKAGE_TICKET_PAGE,
    component: PackTicketPage,
    props: true,
  },
  {
    path: "/:lang?/voucher_group_transaction/:encryptedId",
    name: ROUTE_PACKAGE_TICKET_QR_PAGE,
    component: PackageTicketTransactionPage,
    props: true,
  },
  {
    path: "/restaurants/:restaurantName/package/:packageId",
    redirect: (to) => {
      return {
        path: `/restaurants/${to.params.restaurantName}/package/${to.params.packageId}/menu`,
      };
    },
  },
  {
    path: "/restaurants/:restaurantName/package/:packageId/menu",
    name: "PackageMenuPage",
    component: PackageMenuPage,
    props: true,
  },
  {
    path: "/restaurants/:restaurantName",
    name: ROUTE_RESTAURANT_PAGE,
    component: RestaurantPage,
    beforeEnter: restaurantPageGuard,
    props: true,
    meta: { scrollToTop: true },
    alias: "/corporate-restaurants/:restaurantName",
    children: [
      {
        path: "buy-voucher",
        name: ROUTE_BUY_RESTAURANT_VOUCHER_SIDEBAR,
      },
      {
        path: "buy-package-voucher",
        name: ROUTE_BUY_PACKAGE_TICKET_SIDEBAR,
      },
      {
        path: "book/step-1",
        name: "sidebar-booking-step-1",
        component: BookingStep1,
        beforeEnter: sidebarBookingGuard,
      },
      {
        path: "book/step-2",
        name: "sidebar-booking-step-2",
        component: BookingStep2,
        beforeEnter: sidebarBookingGuard,
      },
      {
        path: "book/step-3",
        name: "sidebar-booking-step-3",
        component: BookingStep3,
        beforeEnter: sidebarBookingGuard,
      },
      {
        path: "book/step-4",
        name: "sidebar-booking-step-4",
        component: BookingStep4,
        beforeEnter: sidebarBookingGuard,
      },
      {
        path: "book/step-5",
        name: "sidebar-booking-step-5",
        component: BookingStep5,
        beforeEnter: sidebarBookingGuard,
      },
    ],
  },
  {
    path: "/:lang?/restaurants/:restaurantName/mobile-book",
    name: "Book",
    component: BookingPage,
    props: true,
    beforeEnter: bookingPageGuard,
    children: [
      {
        path: "step-1",
        name: "booking-page-step-1",
        component: BookingStep1,
        beforeEnter: mobileBookingGuard,
      },
      {
        path: "step-2",
        name: "booking-page-step-2",
        component: BookingStep2,
        beforeEnter: mobileBookingGuard,
      },
      {
        path: "step-3",
        name: "booking-page-step-3",
        component: BookingStep3,
        beforeEnter: mobileBookingGuard,
      },
      {
        path: "step-4",
        name: "booking-page-step-4",
        component: BookingStep4,
        beforeEnter: mobileBookingGuard,
      },
      {
        path: "step-5",
        name: "booking-page-step-5",
        component: BookingStep5,
        beforeEnter: mobileBookingGuard,
      },
    ],
  },
  {
    path: "/:lang?/restaurants/:restaurantName/mobile-buy-voucher",
    name: ROUTE_BUY_RESTAURANT_VOUCHER_PAGE,
    component: BuyVoucherPage,
    beforeEnter: buyVoucherPageGuard,
    props: true,
  },
  {
    path: "/:lang?/restaurants/:restaurantName/mobile-buy-package-voucher",
    name: ROUTE_BUY_PACKAGE_TICKET_PAGE,
    component: BuyPackTicketPage,
    beforeEnter: buyVoucherPageGuard,
    props: true,
  },
  {
    path: "/restaurants/:restaurantName/landing/:encryptedId",
    name: ROUTE_BOOKING_LANDING_PAGE,
    component: LandingPage,
    props: true,
  },
  {
    path: "/restaurants/:restaurantName/landing-package-ticket/:encryptedId",
    name: ROUTE_PACKAGE_TICKET_LANDING_PAGE,
    component: PackageTicketLandingPage,
    props: true,
  },
  {
    path: "/restaurants/:restaurantName/landing-package-ticket/qr-payment/:encryptedId",
    name: ROUTE_PACKAGE_TICKET_QR_PAYMENT_LANDING_PAGE,
    component: PackTicketQRPaymentLandingPage,
    props: true,
  },
  {
    path: "/restaurants/:restaurantName/payment-success/:encryptedReservationId",
    name: ROUTE_PAYMENT_SUCCESS,
    component: PaymentSuccessPage,
    props: true,
  },
  {
    path: "/restaurants/:restaurantName/payment-failed",
    name: ROUTE_PAYMENT_FAILED,
    component: PaymentFailedPage,
    props: true,
  },
  {
    path: "/restaurants/:restaurantName/payment-otp/:url",
    name: ROUTE_PAYMENT_OTP,
    component: PaymentOTPPage,
    props: true,
  },
  {
    path: "/restaurants/:restaurantName/voucher/:encryptedId",
    name: ROUTE_VOUCHER_LANDING_PAGE,
    component: VoucherLandingPage,
    props: true,
  },
  {
    path: "/restaurants/:restaurantName/voucher/qr-payment/:encryptedId",
    name: ROUTE_VOUCHER_QR_PAYMENT_LANDING_PAGE,
    component: VoucherQRPaymentLandingPage,
    props: true,
  },
  {
    path: "/restaurants/group/:groupLandingId",
    name: ROUTE_GROUP_LANDING_PAGE,
    component: GroupLandingPage,
    props: true,
  },
  {
    path: "/download",
    name: ROUTE_DOWNLOAD_APP,
    component: DownloadAppPage,
  },
  {
    path: "/register",
    name: "register",
    component: HomePage,
    beforeEnter: registerPageGuard,
  },
  {
    path: "/top-brands",
    name: "top-brands",
    component: TopBrandPage,
    props: true,
  },
  {
    path: "/:groupLandingId",
    name: "group-landing-alias",
    component: GroupLandingPage,
    props: true,
  },
  {
    path: "/error/:errorId",
    name: "error",
    component: ErrorPage,
    props: true,
  },
  {
    path: "*",
    name: ROUTE_NOT_FOUND,
    component: NotFoundPage,
  },
];

// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
// handle promise rejection when failed go to new route
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    const matchedRoute = to.matched[to.matched.length - 1];
    if (screen === "phone" || matchedRoute.meta?.scrollToTop) {
      return { x: 0, y: 0 };
    } else {
      return savedPosition;
    }
  },
  parseQuery(query) {
    const parsed = qs.parse(query);
    return qs.parse(parsed);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query, {
      arrayFormat: "brackets",
      encode: false,
    });
    return result ? `?${result}` : "";
  },
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
  if (
    !hasQueryParams(to) &&
    hasQueryParams(from) &&
    !from.meta?.discardQueryString
  ) {
    const newRoute = { ...to };
    newRoute.query = from.query;
    next(newRoute);
  } else {
    next();
  }
});

router.afterEach((to) => {
  const parsedURL = `${baseURL}${to.fullPath}`;
  sendEvent("Page browse", {
    url: parsedURL,
  });
});

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    alert.error("Oops, error when loading the page, please refresh");
    rollbar.warning(error.message);
  } else {
    rollbar.warning(error.message);
  }
});

export default router;
