export default async function askUseCurrentLocation(paramOption = {}) {
  const currentStatus = await checkCurrentPermission();
  if (currentStatus.state === "prompt" || currentStatus.state === "granted") {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        const defaultOption = {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 0,
        };
        const option = { ...defaultOption, ...paramOption };
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const geoLocation = {
              lat: 0,
              lng: 0,
            };
            geoLocation.lat = position.coords.latitude;
            geoLocation.lng = position.coords.longitude;
            resolve(geoLocation);
          },
          (param) => {
            const error = {
              data: param,
              message:
                "Please turn on permission location on your device settings",
              cause: param,
            };
            reject(error);
          },
          option
        );
      } else {
        return;
      }
    });
  }
  const error = {
    data: null,
    message: "Please allow location access in your browser",
    cause: null,
    warning: true,
  };
  return Promise.reject(error);
}

async function checkCurrentPermission() {
  if ("permissions" in navigator) {
    return await navigator.permissions.query({ name: "geolocation" });
  } else {
    return "granted";
  }
}
