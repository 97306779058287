import { CURRENCY_SYMBOL } from "@/lib/constant";

const stripHtmlTag = (text: string): string => {
  if (text) {
    return text.replace(/(<([^>]+)>)/gi, "").replace(/\s*/, "");
  }
  return "";
};

// to remove another number value
// example: 5,555Baht --> 5555
const convertToNumber = (param: string | number): number => {
  if (typeof param === "number") {
    return param;
  } else if (typeof param === "string" && param.length > 0) {
    // to remove character after ','
    const split = param.split(".");
    // to remove character if it is not a number
    const removeNonNumber = split[0].match(/\d+/g);
    const finalNumber =
      removeNonNumber !== null ? parseInt(removeNonNumber.join("")) : 0;
    return finalNumber;
  }
  return 0;
};

// Convert a price to a float
// ฿200.50 --> 200.50
// $1,000.75 --> 1000.75
const convertToFloat = (param: string | number): number => {
  if (typeof param === "number") {
    return param;
  } else if (typeof param === "string" && param.length > 0) {
    // Use regular expression to extract the numeric part
    const numericString = param.match(/[0-9,.]+/);

    // Check if a numeric string is found
    if (numericString) {
      // Replace commas (if any) and parse as a float
      const cleanNumericString = numericString[0].replace(/,/g, "");
      const floatValue = parseFloat(cleanNumericString);
      return floatValue;
    }
  }
  return 0;
};

const removePunctuation = (text: string): string => {
  const punctuation = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
  if (text && text.length) {
    try {
      return text.replace(punctuation, "");
    } catch (err) {
      return text;
    }
  }
  return "";
};

const numberFormatThousand = (param: number): string => {
  if (typeof param === "number") {
    return param.toLocaleString("en-US");
  }
  return convertToNumber(param).toLocaleString("en-US");
};

const moneyFormat = (param: number | string): string => {
  const value = convertToNumber(param);
  const intl = new Intl.NumberFormat("th-TH", {
    // style: "currency",
    // currency: "THB",
    maximumFractionDigits: 0,
  });
  return `${intl.format(value)}฿`;
};

const getCurrencySymbol = (val: string) => {
  if (typeof val !== "string") {
    return "";
  }
  let currency = "";
  CURRENCY_SYMBOL.forEach((symbol) => {
    const indexOf = val.indexOf(symbol);

    if (indexOf !== -1) {
      if (!currency) {
        currency = val.charAt(indexOf);
      }
    }
  });
  return currency;
};

const addCurrency = ({
  val,
  symbol = "฿",
  additionalSsign = false,
}: {
  val: string | number;
  symbol?: string;
  additionalSsign?: boolean;
}) => {
  if (
    typeof val === "string" &&
    CURRENCY_SYMBOL.some((substring) => val.includes(substring))
  ) {
    if (additionalSsign && symbol === "SGD") {
      return `S${val}`;
    }
    return val;
  }
  const parsedSymbol =
    typeof symbol !== "string"
      ? "฿"
      : symbol.includes("SGD")
      ? "S$"
      : symbol.includes("THB")
      ? "฿"
      : symbol;
  const intl = new Intl.NumberFormat();
  const formated = intl.format(convertToNumber(val));
  return `${parsedSymbol}${formated}`;
};

const isValidJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const toSlug = (param: string) => {
  return param
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

export {
  stripHtmlTag,
  convertToNumber,
  convertToFloat,
  removePunctuation,
  numberFormatThousand,
  isValidJsonString,
  toSlug,
  moneyFormat,
  addCurrency,
  getCurrencySymbol,
};
