import {
  googleReserve,
  involveAsia,
  utm,
  accessTrade,
} from "@/services/referrer";

function getUTMSource() {
  const defaultUtmSource = "web";
  const { get: getGoogleReserve } = googleReserve();
  const { get: getInvolveAsiaClickId } = involveAsia();
  const { get: getAccessTradeClickId } = accessTrade();
  const { get: getUtm } = utm();
  const { utmMedium, utmSource, utmVersion } = getUtm();
  const involveAsiaClickId = getInvolveAsiaClickId();
  const googleReserveToken = getGoogleReserve();
  const accessTradeClickId = getAccessTradeClickId();
  let selectedUtm = "";
  if (
    typeof utmVersion === "string" &&
    utmVersion === "v2" &&
    typeof utmMedium === "string" &&
    utmMedium.length
  ) {
    selectedUtm = utmMedium;
  } else {
    selectedUtm = utmSource;
  }
  if (typeof involveAsiaClickId === "string" && involveAsiaClickId.length > 0) {
    return "InvolveAsia";
  }
  if (typeof googleReserveToken === "string" && googleReserveToken.length > 0) {
    return "rwg";
  }
  if (typeof accessTradeClickId === "string" && accessTradeClickId.length > 0) {
    return getUtm().utmSource;
  }
  return typeof selectedUtm === "string" && selectedUtm.length
    ? selectedUtm
    : defaultUtmSource;
}

export { getUTMSource };
