import { isEmpty, orderBy } from "lodash-es";
import Rollbar from "./rollbar";
import useHttp from "@/composable/useHttp";

const getPlaceID = async (geoLocation) => {
  const defaultErrorMessage = "failed get place ID from lat, lng";
  try {
    const url = `/google_map/find_place_id.json?lat=${geoLocation.lat}&lng=${geoLocation.lng}`;
    const { data: response, error } = await useHttp({
      method: "GET",
      url,
      canRetry: true,
    });
    if (error.message || !response) {
      if (error.shouldReport) {
        Rollbar.error(error.message, {
          message: error.cause,
          data: error.data,
        });
      }
      return {
        success: false,
        message: error.message || defaultErrorMessage,
        data: null,
      };
    }
    const { data, message, success } = response;
    if (!success || isEmpty(data)) {
      Rollbar.error("Invalid place ID response", {
        message: message || "Invalid place ID response",
        data: {
          placeId,
          lat: geoLocation.lat,
          lng: geoLocation.lng,
        },
      });
      return {
        success: false,
        message: message || defaultErrorMessage,
        data: null,
      };
    }
    const placeId = data.placeId;
    if (typeof placeId !== "string" || placeId.length === 0) {
      Rollbar.error("Invalid place ID", {
        message: "Invalid place ID",
        data: {
          placeId,
          lat: geoLocation.lat,
          lng: geoLocation.lng,
        },
      });

      return {
        success: false,
        message: "Invalid place ID",
        data: null,
      };
    }
    return {
      success: true,
      message: "Success",
      data: placeId,
    };
  } catch (err) {
    const error = {
      data: err,
      cause: err,
      message: "failed get place ID from lat, lng",
    };
    Rollbar.error(error.message, {
      message: error.cause,
      data: error.data,
    });
    return {
      success: false,
      message: error.message || defaultErrorMessage,
      data: null,
    };
  }
};

const getPlaceDetail = (placeId) => {
  return new Promise((resolve, reject) => {
    try {
      const placeService = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
      placeService.getDetails(
        {
          placeId,
          fields: ["name", "formatted_address", "geometry.location"],
        },
        (place) => {
          if (!place) {
            const error = {
              data: place,
              cause: "Google map API return wrong format",
              message: "failed get place detail from place ID",
            };
            Rollbar.error(error.message, {
              message: error.cause,
              data: error.data,
            });
            reject(error);
          } else {
            const selectedLocation = {
              placeName: place.name,
              address: place.formatted_address,
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };
            resolve(selectedLocation);
          }
        }
      );
    } catch (err) {
      const error = {
        data: err,
        cause: err,
        message: "failed get place detail from place ID",
      };
      Rollbar.error(error.message, {
        message: error.cause,
        data: error.data,
      });
      reject(error);
    }
  });
};

const getAddress = (geoLocation) => {
  return new Promise((resolve, reject) => {
    if (geoLocation === null) {
      const error = {
        data: geoLocation,
        cause: "Required parammter 'geo location' not found",
        message: "failed get place detail from place ID",
      };
      Rollbar.error(error.message, {
        message: error.cause,
        data: error.data,
      });
      reject(error);
    } else {
      try {
        // call the function
        getPlaceID(geoLocation)
          .then((response) => {
            if (response.success === false) {
              reject(response);
              return;
            }
            getPlaceDetail(response.data).then((selectedLocation) => {
              resolve(selectedLocation);
            });
          })
          .catch((err) => {
            if (err === "ZERO_RESULTS") {
              reject({
                data: "",
                message: "address not found",
              });
            } else {
              const error = {
                data: err,
                cause: err,
                message: "failed get Address",
              };
              Rollbar.error(error.message, {
                message: error.cause,
                data: error.data,
              });
              reject(error);
            }
          });
      } catch (err) {
        const error = {
          data: err,
          cause: err,
          message: "failed get Address",
        };
        Rollbar.error(error.message, {
          message: error.cause,
          data: error.data,
        });
        reject(error);
      }
    }
  });
};

const getDistanceToRestaurant = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      const lat = payload.currentLocation.lat;
      const lng = payload.currentLocation.lng;
      const restLat = payload.restaurantLocation.lat;
      const restLng = payload.restaurantLocation.lng;

      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: new window.google.maps.LatLng(restLat, restLng),
          destination: new window.google.maps.LatLng(lat, lng),
          travelMode: "DRIVING",
          avoidTolls: true,
          provideRouteAlternatives: true,
          unitSystem: window.google.maps.UnitSystem.METRIC,
        },
        (response, status) => {
          if (status === "OK") {
            if (response.routes.length > 0) {
              const arrayOfDistance = response.routes.map((route) => {
                return route.legs[0];
              });
              const shortedDistanceFirst = orderBy(
                arrayOfDistance,
                "distance.value"
              );
              resolve(shortedDistanceFirst[0].distance);
            } else {
              const error = {
                cause: "No routes found from user to restaurant",
                message:
                  "Oops, no routes found from your location to the restaurant",
              };
              Rollbar.error(error.message, error.cause);
              reject(error);
            }
          } else if (status === "ZERO_RESULTS") {
            const error = {
              cause: "No routes found from user to restaurant",
              message:
                "Oops, no routes found from your location to the restaurant",
            };
            Rollbar.error(error.message, error.cause);
            reject(error);
          } else {
            const error = {
              cause: response,
              message:
                "Oops, something went wrong, failed to calculate distance",
            };
            Rollbar.error(error.message, error.cause);
            reject(error);
          }
        }
      );
    } catch (err) {
      const error = {
        cause: err,
        message: "Oops, something went wrong, failed to calculate distance",
      };
      Rollbar.error(error.message, error.cause);

      reject(error);
    }
  });
};

export { getAddress, getPlaceID, getPlaceDetail, getDistanceToRestaurant };
