import { ref, computed } from "@vue/composition-api";
import { useStorage } from "@vueuse/core";
import { isAllowLocalStorage } from "@/helper/storagePermissionHelper";
import {
  getAvailableCities as serviceGetCities,
  getCityByIPAddress as serviceGetCityByIP,
} from "@/services/common";
import * as alert from "@/lib/alert";
import { isEmpty } from "lodash-es";

type AvailableCities = {
  id: string;
  type: string;
  name: string;
  homeDescription: string;
  icon: {
    url: string;
  };
  countryId: number | string;
  countryIcon: {
    url: string;
  };
  isMiniHomepage: boolean | undefined;
}[];
const allCityOption = {
  name: "All Cities",
  homeDescription: "",
  type: "",
  id: "",
  icon: {
    url: "",
  },
  countryId: "",
  countryIcon: {
    url: "",
  },
  isMiniHomepage: false,
};
const localStorageKey = "hungryhub_selected_city_id";
const defaultSelectedCityId = ref("1");

const isLoading = ref(true);
const selectedCityId = isAllowLocalStorage()
  ? useStorage(localStorageKey, defaultSelectedCityId.value)
  : ref(defaultSelectedCityId.value);

const availableCities = ref<AvailableCities>([]);
const emptyCity = {
  name: "",
  homeDescription: "",
  type: "",
  id: 0,
  isMiniHomepage: false,
  icon: {
    url: "",
  },
  countryIcon: {
    url: "",
  },
};

const selectedCity = computed(() => {
  if (
    isLoading.value === true ||
    !selectedCityId.value ||
    !availableCities.value ||
    isEmpty(availableCities.value)
  ) {
    return emptyCity;
  }
  const filter = availableCities.value.filter(
    (city) => city.id == selectedCityId.value
  );
  if (filter.length) {
    return filter[0];
  }
  return emptyCity;
});

const localStorageKeyCountry = "hungryhub_selected_country_id";
const defaultSelectedCountry = ref("218");
const selectedCountryId = isAllowLocalStorage()
  ? useStorage(localStorageKeyCountry, defaultSelectedCountry.value)
  : ref(defaultSelectedCountry.value);
const selectedCityName = computed(() => {
  return selectedCity.value.name;
});

const selectedCityHomeDescription = computed(() => {
  return selectedCity.value.homeDescription !== undefined
    ? selectedCity.value.homeDescription
    : "";
});

const isMiniHomepage = computed(() => {
  if (selectedCity.value) {
    return selectedCity.value.isMiniHomepage;
  } else {
    return false;
  }
});

async function getAvailableCities() {
  isLoading.value = true;
  const result = await serviceGetCities();
  if (result.isSuccess && Array.isArray(result.data)) {
    availableCities.value.push(allCityOption);
    result.data.forEach((city) => {
      availableCities.value.push({
        id: city.id,
        type: city.type,
        ...city.attributes,
      });
    });

    if (!isEmpty(selectedCity.value)) {
      const findCity = availableCities.value.find(
        (city) => city.id === selectedCityId.value && city.id !== ""
      );
      if (!findCity) {
        if (isAllowLocalStorage()) {
          localStorage.setItem(localStorageKey, defaultSelectedCityId.value);
        }
      }
      selectedCityId.value = useStorage(
        localStorageKey,
        defaultSelectedCityId.value
      ).value;
    }

    isLoading.value = false;
    return;
  }
  alert.error(result.message);
}

async function setCityByIPAddress() {
  const { isSuccess, data } = await serviceGetCityByIP();
  if (isSuccess) {
    const { countryId } = data;
    if (countryId === 198) {
      if (availableCities.value.length) {
        const checkSingapore = availableCities.value.filter(
          (city) => city.countryId === 198
        );
        if (checkSingapore.length) {
          defaultSelectedCityId.value = checkSingapore[0].id;
          defaultSelectedCountry.value = `${checkSingapore[0].countryId}`;
        }
      }
    }
  } else {
    defaultSelectedCityId.value = "1";
    defaultSelectedCountry.value = "218";
  }
}

export {
  emptyCity,
  isMiniHomepage,
  selectedCountryId,
  selectedCity,
  selectedCityId,
  selectedCityName,
  selectedCityHomeDescription,
  isLoading,
  availableCities,
  getAvailableCities,
  setCityByIPAddress,
};
