import {
  storageSetEnvironment,
  storageGetEnvironment,
} from "@/lib/localStorage";
import store from "@/store";
import useQueryString from "@/composable/useQueryString";
import { ref, computed } from "@vue/composition-api";
import {
  ENV_MODE_ENGINEERING,
  ENV_MODE_BALLBOT,
  ENV_MODE_VENUS,
  ENV_MODE_PRODUCTION_SUPPORT,
  ENV_MODE_PRODUCTION,
  ENV_MODE_NEW_STAGING,
  ENV_MODE_USING_FILE,
} from "@/lib/constant";
type Environment = {
  label: string;
  id: string;
  apiDomain: string;
  nuxtDomain: string;
  pegasusDomain: string;
  subWebDomain: string;
  feature: string;
  [key: string]: string; // Add index signature
};
export default function useEnvironment() {
  const defaultEnvironment: Environment = {
    label: "Use Env",
    id: ENV_MODE_USING_FILE,
    apiDomain: process.env.VUE_APP_API_DOMAIN || "",
    nuxtDomain: process.env.VUE_APP_NUXT_WEB_DOMAIN || "",
    subWebDomain: process.env.VUE_APP_SUB_WEB_DOMAIN || "",
    pegasusDomain: process.env.VUE_APP_PEGASUS_DOMAIN || "",
    feature: "",
  };
  const environments = ref<Environment[]>([
    defaultEnvironment,
    {
      label: "Use Engineering",
      id: ENV_MODE_ENGINEERING,
      apiDomain: "https://hh-engineering.my.id",
      nuxtDomain: "https://hh-nuxt-staging.netlify.app",
      subWebDomain: "https://hh-fwb-staging.netlify.app",
      pegasusDomain: "https://hh-pegasus-staging.netlify.app",
      feature: "",
    },
    {
      label: "Use New Staging",
      id: ENV_MODE_NEW_STAGING,
      apiDomain: "https://hhstaging.dev",
      nuxtDomain: "https://hh-nuxt-staging.netlify.app",
      subWebDomain: "https://hh-fwb-staging.netlify.app",
      pegasusDomain: "https://hh-pegasus-staging.netlify.app",
      feature: "",
    },
    {
      label: "Use Ballbot",
      id: ENV_MODE_BALLBOT,
      apiDomain: "https://hh-ballbot.my.id",
      nuxtDomain: "https://hh-nuxt-staging.netlify.app",
      subWebDomain: "https://hh-fwb-staging.netlify.app",
      pegasusDomain: "https://hh-pegasus.pages.dev",
      feature: "",
    },
    {
      label: "Use Venus",
      id: ENV_MODE_VENUS,
      apiDomain: "https://hh-venus.my.id",
      nuxtDomain: "https://hh-nuxt-staging.netlify.app",
      subWebDomain: "https://hh-fwb-staging.netlify.app",
      pegasusDomain: "https://hh-pegasus-venus.pages.dev",
      feature: "",
    },
    {
      label: "Use Production Support",
      id: ENV_MODE_PRODUCTION_SUPPORT,
      apiDomain: "https://prod-support.hhub.ly",
      nuxtDomain: "https://hh-nuxt-staging.netlify.app",
      subWebDomain: "https://hh-fwb-staging.netlify.app",
      pegasusDomain: "https://hh-pegasus-staging.netlify.app",
      feature: "",
    },
    {
      label: "Use Production",
      id: ENV_MODE_PRODUCTION,
      apiDomain: "https://hungryhub.com",
      nuxtDomain: "https://checkout.hungryhub.com",
      subWebDomain: "https://web2.hungryhub.com",
      pegasusDomain: "https://hh-pegasus.netlify.app",
      feature: "",
    },
  ]);
  const selectedEnvironment = ref<Environment>({
    label: "",
    id: "",
    apiDomain: "",
    nuxtDomain: "",
    subWebDomain: "",
    pegasusDomain: "",
    feature: "",
  });

  function saveEnvToStorage(payloadEnv: Environment) {
    storageSetEnvironment(payloadEnv);
  }
  function getEnvFromStorage(): Environment | null {
    return storageGetEnvironment();
  }
  function saveEnvToStore(payloadEnv: Environment) {
    store.commit("webConfig/setEnvironment", payloadEnv);
  }
  function saveEnvironment(payloadEnv: Environment) {
    saveEnvToStore(payloadEnv);
    saveEnvToStorage(payloadEnv);
    window.apiDomain = payloadEnv.apiDomain;
  }

  const envQueryParam = computed(() => {
    const queryParam = {
      env: {
        existInQueryParam: false,
        val: "",
      },
      apiDomain: {
        existInQueryParam: false,
        val: "",
      },
      nuxtDomain: {
        existInQueryParam: false,
        val: "",
      },
      pegasusDomain: {
        existInQueryParam: false,
        val: "",
      },
      subWebDomain: {
        existInQueryParam: false,
        val: "",
      },
      feature: {
        existInQueryParam: false,
        val: "",
      },
    };
    const { getQueryString } = useQueryString();
    const { env, nuxtDomain, subWebDomain, feature, apiDomain, pegasusDomain } =
      getQueryString();
    queryParam.env.existInQueryParam = env !== undefined;
    queryParam.env.val = env ? env.toLowerCase() : "";
    queryParam.nuxtDomain.existInQueryParam = nuxtDomain !== undefined;
    queryParam.nuxtDomain.val = nuxtDomain ? atob(nuxtDomain) : "";
    queryParam.subWebDomain.existInQueryParam = subWebDomain !== undefined;
    queryParam.subWebDomain.val = subWebDomain ? atob(subWebDomain) : "";
    queryParam.pegasusDomain.existInQueryParam = pegasusDomain !== undefined;
    queryParam.feature.existInQueryParam = feature !== undefined;
    queryParam.feature.val = feature ? feature : "";
    queryParam.apiDomain.existInQueryParam = apiDomain !== undefined;
    queryParam.apiDomain.val = apiDomain ? atob(apiDomain) : "";

    return queryParam;
  });

  function setDefaultEnv(index: number) {
    const { nuxtDomain, subWebDomain, feature, apiDomain, pegasusDomain, env } =
      envQueryParam.value;
    const envFromStorage = getEnvFromStorage();
    const getStorageValue = (key: keyof Environment) =>
      envFromStorage?.[key] || "";

    const setEnvValue = (
      key: string,
      queryParam: { existInQueryParam: boolean; val: string },
      storageKey: keyof Environment
    ) => {
      if (queryParam.existInQueryParam) {
        environments.value[index][key] = queryParam.val;
      } else {
        // prever to use value from query param than local storage
        if (env.existInQueryParam) {
          const indexFromQueryParam = environments.value.findIndex(
            (item) => item.id === env.val
          );
          const findIndex = indexFromQueryParam >= 0 ? indexFromQueryParam : 0;
          environments.value[index][key] = environments.value[findIndex][key];
        } else {
          const storageValue = getStorageValue(storageKey);
          if (storageValue) {
            environments.value[index][key] = storageValue;
          }
        }
      }
    };
    setEnvValue("nuxtDomain", nuxtDomain, "nuxtDomain");
    setEnvValue("subWebDomain", subWebDomain, "subWebDomain");
    setEnvValue("pegasusDomain", pegasusDomain, "pegasusDomain");
    setEnvValue("feature", feature, "feature");
    setEnvValue("apiDomain", apiDomain, "apiDomain");
  }

  function initEnvironment() {
    if (
      store.getters["webConfig/isOnProduction"] &&
      !store.getters["user/isHungryHubEmail"]
    ) {
      saveEnvToStore(defaultEnvironment);
      window.apiDomain = defaultEnvironment.apiDomain;
      return;
    }
    const { env, nuxtDomain, subWebDomain, apiDomain, pegasusDomain } =
      envQueryParam.value;
    const envFromStorage: Environment | null = getEnvFromStorage();
    const idEnvStorage: string = envFromStorage ? envFromStorage.id : "";

    let index = 0;
    // prioritize value from query Param rather than local storage
    if (env.existInQueryParam) {
      const indexFromQueryParam = environments.value.findIndex(
        (item) => item.id === env.val
      );
      index = indexFromQueryParam >= 0 ? indexFromQueryParam : 0;
    } else if (idEnvStorage) {
      const indexFromStorage = environments.value.findIndex(
        (item) => item.id === idEnvStorage.toLowerCase()
      );
      index = indexFromStorage >= 0 ? indexFromStorage : 0;
    }
    if (!env.existInQueryParam && !envFromStorage) {
      if (
        apiDomain.existInQueryParam ||
        nuxtDomain.existInQueryParam ||
        subWebDomain.existInQueryParam ||
        pegasusDomain.existInQueryParam
      ) {
        setDefaultEnv(index);
        saveEnvironment(environments.value[index]);
        return;
      }
      saveEnvToStore(defaultEnvironment);
      window.apiDomain = defaultEnvironment.apiDomain;
    } else {
      setDefaultEnv(index);
      saveEnvironment(environments.value[index]);
    }
  }

  return {
    environments,
    defaultEnvironment,
    selectedEnvironment,
    saveEnvironment,
    getEnvFromStorage,
    saveEnvToStore,
    initEnvironment,
  };
}
