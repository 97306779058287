<template>
  <div id="app">
    <HeaderPartner v-if="isPartner" />
    <template v-if="layout === 'plain'">
      <router-view />
    </template>
    <template v-else>
      <div id="panel" class="relative flex flex-col" style="min-height: 100vh">
        <component
          :is="bannerComponent"
          v-if="isMobile && isShowBanner"
          :app-first-text="config.appfirstBannerForMobile"
        ></component>
        <TopBar v-if="renderTopbar" />
        <Navbar
          v-if="renderNavbar"
          @on-register-clicked="openRegisterModal"
          @on-signin-clicked="toggleSignInModal(true)"
        />
        <router-view />
        <Footer v-if="readyRenderFooter" class="mt-auto" />
      </div>
      <LoginModal />
      <RegisterModal />
      <ForgotPasswordModal />
    </template>
    <ServerBusyModal />
    <component :is="bottomSheetComponent"></component>
    <div id="modal-container"></div>
    <portal-target :name="PORTAL_FOR_MODAL_SELECTOR" multiple></portal-target>
    <PopUpDisclaimerPartner v-if="isShowPopUpDisclaimerPartner" />
    <FloatingButtonSetting v-if="isShowDevToolsComponent" />
    <EnvironmentAlert v-if="isShowDevToolsComponent" />
  </div>
</template>
<script>
import "pretty-checkbox/src/pretty-checkbox.scss";
import "placeholder-loading/dist/css/placeholder-loading.min.css";
import "@/assets/css/tailwind.css";
import "@/assets/index.scss";
import "@/assets/css/AppCritical.scss";
import { mapGetters } from "vuex";
import {
  isAllowLocalStorage,
  isAllowCookie,
} from "@/helper/storagePermissionHelper";
import TopBar from "@/components/TopBar.vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import LoginModal from "@/components/Common/Login/LoginModal.vue";
import ServerBusyModal from "@/components/Common/ServerBusy/ServerBusyModal.vue";
import RegisterModal from "@/components/Common/Register/RegisterModal.vue";
import ForgotPasswordModal from "@/components/Common/ForgotPassword/ForgotPasswordModal.vue";
import { methods as realtimeQRMethods } from "@/composable/realtimeQRPaymentChecking";
import { methods as favouriteMethods } from "@/composable/favouriteRestaurant";
import {
  UTM_SOURCE,
  PORTAL_FOR_MODAL_SELECTOR,
  ROUTE_HOME_PAGE,
} from "@/lib/constant";
import { isOpen as isServerBusyModalOpen } from "@/components/Common/ServerBusy/serverBusy";
import { mapState } from "vuex";
import { getAllGroupLanding } from "@/composable/groupLanding";
import { mapFields } from "vuex-map-fields";
import {
  getAvailableCities,
  setCityByIPAddress,
} from "@/composable/selectCity";
import { initFirebasePerf } from "@/lib/firebasePerf";
import { getFromUserStorage } from "@/helper/userStorage";
import qs from "qs";
import humps from "humps";
import {
  processRedirectLoginResult,
  needInputEmail,
  needInputPhone,
} from "@/composable/socialMediaLogin";
import useLazyImport from "@/composable/useLazyImport";
import { getPreferedLang } from "@/helper/language";
import {
  involveAsia,
  googleReserve,
  utm,
  accessTrade,
} from "@/services/referrer";
import { usePartner } from "@/composable/usePartner";
import {
  bottomSheetComponent,
  loadBottomSheetComponent,
} from "@/components/Common/BottomSheet/bottomSheet";
import { onMounted } from "@vue/composition-api";
import useEnvironment from "@/composable/useEnvironment";
import { generateAlternateUrl } from "@/helper/urlHelper";

const DEFAULT_TITLE = "Hungry Hub, Thailand's No.1 Dining Offer App";
const parsedQueryString = humps.camelizeKeys(
  qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
);
let timeOutInstance;
export default {
  components: {
    PopUpDisclaimerPartner: () =>
      useLazyImport(() =>
        import(
          /* webpackChunkName: "PopUpDisclaimerPartner" */ "@/components/PopUpDisclaimerPartner.vue"
        )
      ),
    HeaderPartner: () =>
      useLazyImport(() =>
        import(
          /* webpackChunkName: "HeaderPartner" */ "@/components/HeaderPartner.vue"
        )
      ),
    Navbar,
    TopBar,
    Footer,
    LoginModal,
    RegisterModal,
    ForgotPasswordModal,
    ServerBusyModal,
    FloatingButtonSetting: () =>
      useLazyImport(() =>
        import(
          /* webpackChunkName: "FloatingButtonSetting" */ "@/components/DevelopmentMode/FloatingButtonSetting.vue"
        )
      ),
    EnvironmentAlert: () =>
      useLazyImport(() =>
        import(
          /* webpackChunkName: "EnironmentAlert" */ "@/components/DevelopmentMode/EnvironmentAlert.vue"
        )
      ),
  },
  setup() {
    const { getFavoriteRestaurants } = favouriteMethods;
    const { initPartner, isPartner, isPartnerTagThai, layout } = usePartner();
    onMounted(async () => {
      await initPartner();
    });
    const { initEnvironment } = useEnvironment();

    return {
      getFavoriteRestaurants,
      isServerBusyModalOpen,
      initEnvironment,
      PORTAL_FOR_MODAL_SELECTOR,
      isPartner,
      isPartnerTagThai,
      layout,
      bottomSheetComponent,
      loadBottomSheetComponent,
    };
  },
  data() {
    return {
      bannerComponent: null,
    };
  },
  computed: {
    ...mapState("webConfig", [
      "config",
      "openLoginModal",
      "renderTopbar",
      "renderNavbar",
      "readyRenderFooter",
    ]),
    ...mapFields("webConfig", ["apiDomain", "subWebDomain", "nuxtDomain"]),
    ...mapState("user", ["name", "cleverTapObj"]),
    ...mapState(["isGoogleMapsScriptLoaded"]),
    ...mapFields("user", ["isLoading"]),
    ...mapGetters("user", ["isUserSignedIn", "isHungryHubEmail"]),
    hhLang() {
      return this.$store.state.lang;
    },
    pageDescription() {
      return this.config.appDescription || DEFAULT_TITLE;
    },
    isShowBanner() {
      return (
        this.$route.name === ROUTE_HOME_PAGE &&
        this.$store.getters["webConfig/enableFeatures"].firstAppVoucher
      );
    },
    isShowPopUpDisclaimerPartner() {
      if (this.isPartnerTagThai) return true;
      return false;
    },
    isShowDevToolsComponent() {
      if (this.isHungryHubEmail) {
        return true;
      }
      if (this.$store.getters["webConfig/isOnProduction"]) {
        return false;
      }
      if (this.isPartner || this.isPartnerTagThai) {
        return false;
      }
      return true;
    },
  },
  watch: {
    openLoginModal(newVal) {
      this.toggleSignInModal(newVal);
    },
    $route() {
      if (this.$route.name === "register") {
        this.openRegisterModal();
      }
    },
  },
  mounted() {
    this.handleReferrer();
    this.initCredential();
    this.doLoadBottomSheetComponent();
    this.openForgotPasswordModal();
    this.checkRedirectLoginResult();
    this.addAppVersion();
    this.checkIsShowTrueWalletPayment();
    this.$nextTick(() => {
      initFirebasePerf();
    });
  },
  created() {
    this.initEnvironment();
    this.loadGoogleMapScript();
    this.forceLangInUrl();
    this.getCities();
    this.getConfig();
    this.setLang();
    getAllGroupLanding();
    if (this.isMobile) {
      this.getRequiredMobileComponent();
    }
  },
  beforeDestroy() {
    clearTimeout(timeOutInstance);
  },
  methods: {
    fixHtmlLang() {
      const html = document.querySelector("html");
      if (html) {
        html.setAttribute("lang", this.$i18nInstance.locale);
      }
    },
    forceLangInUrl() {
      const splitUrl = window.location.pathname.split("/");
      const langSegment = splitUrl[1];
      const urlContainLangSegment =
        langSegment && langSegment.length === 2 && langSegment !== "me"
          ? true
          : false;
      const queryString = parsedQueryString;
      const isUrlContainLangQueryString =
        queryString.locale && queryString.locale.length == 2 ? true : false;
      let finalUrl = "";
      if (urlContainLangSegment) {
        // url contain lang but not 'en' or 'th'
        if (langSegment !== "en" && langSegment !== "th") {
          const suggestedLanguage = getPreferedLang();
          // remove lang from URL segment then append lang as '?lang'
          splitUrl.splice(1, 1);
          queryString.locale = suggestedLanguage;
          const urlSearchStringify = qs.stringify(
            humps.decamelizeKeys(queryString)
          );
          finalUrl = `${window.location.origin}${splitUrl.join(
            "/"
          )}?${urlSearchStringify}`;
          window.location.replace(finalUrl);
        } else {
          // remove lang from URL segment then append lang as '?lang'
          queryString.locale = langSegment;
          const urlSearchStringify = qs.stringify(
            humps.decamelizeKeys(queryString)
          );
          splitUrl.splice(1, 1);
          finalUrl = `${window.location.origin}${splitUrl.join(
            "/"
          )}?${urlSearchStringify}`;
          window.location.replace(finalUrl);
        }
      } else {
        if (!isUrlContainLangQueryString) {
          queryString.locale = getPreferedLang();
          const urlSearchStringify = qs.stringify(
            humps.decamelizeKeys(queryString)
          );
          const finalUrl = `${window.location.origin}${window.location.pathname}?${urlSearchStringify}`;
          window.location.replace(finalUrl);
        }
      }
    },
    async setLang() {
      const lang = Array.isArray(parsedQueryString.locale)
        ? parsedQueryString.locale[0]
        : parsedQueryString.locale;
      if (lang) {
        this.$i18nInstance.locale = lang;
        this.$dayjs.locale(lang);
        this.$veeValidateLocalize(lang);
        await this.$store.dispatch("changeLang", lang);
        this.setFontFamily(lang);
        this.fixHtmlLang(lang);
        return;
      }
      await this.$store.dispatch("changeLang", getPreferedLang());
    },
    openRegisterModal() {
      this.$modal.show("register-modal");
    },
    toggleSignInModal(isShow) {
      if (isShow) {
        this.$modal.show("login-modal");
      } else {
        this.$modal.hide("login-modal");
      }
    },
    openForgotPasswordModal() {
      if (parsedQueryString.forgotpassword) {
        this.$modal.show("forgot-password-modal");
      }
    },
    async doLoadBottomSheetComponent() {
      if (!this.isMobile) {
        return;
      }
      this.loadBottomSheetComponent();
    },
    async initCredential() {
      if (this.isUserSignedIn) {
        const getProfileResult = await this.$store.dispatch("user/getProfile");
        if (getProfileResult.isSuccess) {
          await this.getFavoriteRestaurants();
          this.initEnvironment();
          return;
        }
        this.$store.dispatch("webConfig/toggleLoginModal", true);
        this.$router.push({
          name: ROUTE_HOME_PAGE,
        });
        if (getProfileResult.message) {
          this.$alert.error(getProfileResult.message);
        }
      }
    },
    async checkRedirectLoginResult() {
      if (isAllowLocalStorage() === false || isAllowCookie() === false) {
        return;
      }
      this.isLoading = true;
      const { isSuccess, message } = await processRedirectLoginResult();
      if (isSuccess) {
        this.$alert.success(`Welcome back ${this.name}`);
        this.$store.dispatch("user/sendEvent", "USER_SIGNED_IN");
      }
      if (needInputEmail.value === true || needInputPhone.value === true) {
        this.$store.dispatch("webConfig/toggleLoginModal", true);
      }
      if (!isSuccess && message) {
        this.$alert.error(message);
      }
      this.isLoading = false;
    },
    async getConfig() {
      try {
        await this.$store.dispatch("webConfig/getConfig");
      } catch (err) {
        if (err) {
          this.$alert.error(err);
        }
      }
    },
    async addAppVersion() {
      try {
        const { version } = await require("../package.json");
        const app = document.getElementById("app");
        if (app) {
          app.setAttribute("app-version", version);
        }
      } catch (err) {
        this.$rollbar.error(err);
      }
    },
    loadGoogleMapScript() {
      this.$store.dispatch("renderGoogleMapScript");
    },
    handleReferrer() {
      const { clickId, utmMedium, utmSource, utmVersion, rwgToken, partner } =
        parsedQueryString;
      utm().set({ utmMedium, utmSource, utmVersion });
      involveAsia().set({ clickId });
      googleReserve().set({ rwgToken });
      accessTrade().set({ clickId: partner });
    },
    checkIsShowTrueWalletPayment() {
      const utmSource = getFromUserStorage(UTM_SOURCE);
      if (utmSource === "truemoney") {
        this.$store.commit("booking/setState", {
          state: "showTrueWalletPayment",
          val: true,
        });
      }
    },
    async getRequiredMobileComponent() {
      try {
        const module = await useLazyImport(() =>
          import(
            /* webpackChunkName: "RestaurantBannerMobile" */ "@/components/Common/first_booking/RestaurantBannerMobile.vue"
          )
        );
        this.bannerComponent = module.default;
      } catch (err) {
        this.$rollbar.error(err);
        // if chunkg load error happen
        this.$alert.error("Oops, error when loading the page, please refresh");
      }
    },
    async getCities() {
      await getAvailableCities();
      setCityByIPAddress();
    },
    setFontFamily(lang) {
      // use different font if TH language
      if (lang === "th") {
        window.document
          .getElementsByTagName("body")[0]
          .classList.add("noto-sans-thai");
      }
    },
  },
  metaInfo() {
    const { th, en } = generateAlternateUrl();
    return {
      title: DEFAULT_TITLE,
      titleTemplate: "%s | Hungry hub",
      link: [
        {
          rel: "alternate",
          href: th,
          hreflang: "th",
          vmid: "alternate-link",
        },
        {
          rel: "alternate",
          href: en,
          hreflang: "en",
          vmid: "alternate-link",
        },
      ],
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.pageDescription,
        },
      ],
    };
  },
};
</script>
