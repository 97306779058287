import {
  getFromUserStorage,
  removeFromUserStorage,
  saveToUserStorage,
} from "@/helper/userStorage";
import {
  INVOLVE_ASIA_CLICK_ID,
  UTM_MEDIUM,
  UTM_SOURCE,
  UTM_VERSION,
  GOOGLE_RESERVE_REFERRER_KEY,
  ACCESS_TRADE_CLICK_ID,
} from "@/lib/constant";

function getAndRemove(localStorageKey: string) {
  const storeValue = getFromUserStorage(localStorageKey);
  if (storeValue) {
    removeFromUserStorage(localStorageKey, {
      domain: ".hungryhub.com",
    });
  }
}

export function utm() {
  const set = (payload: {
    utmSource?: string;
    utmMedium?: string;
    utmVersion?: string;
  }) => {
    const { utmMedium, utmSource, utmVersion } = payload;
    if (utmMedium) {
      saveToUserStorage(UTM_MEDIUM, utmMedium, {
        expires: 7,
        domain: ".hungryhub.com",
      });
    }
    if (utmVersion) {
      saveToUserStorage(UTM_VERSION, utmVersion, {
        expires: 7,
        domain: ".hungryhub.com",
      });
    }
    if (utmSource) {
      const storedUtmSource = getFromUserStorage(UTM_SOURCE);
      if (storedUtmSource != utmSource) {
        saveToUserStorage(UTM_SOURCE, utmSource, {
          expires: 7,
          domain: ".hungryhub.com",
        });
      }
    }
  };

  const remove = (payload: {
    utmSource?: boolean;
    utmMedium?: boolean;
    utmVersion?: boolean;
  }) => {
    const { utmMedium, utmSource, utmVersion } = payload;
    if (utmMedium) {
      getAndRemove(UTM_MEDIUM);
    }
    if (utmSource) {
      getAndRemove(UTM_SOURCE);
    }
    if (utmVersion) {
      getAndRemove(UTM_VERSION);
    }
  };

  const get = () => {
    return {
      utmSource: getFromUserStorage(UTM_SOURCE),
      utmMedium: getFromUserStorage(UTM_MEDIUM),
      utmVersion: getFromUserStorage(UTM_VERSION),
    };
  };

  return {
    set,
    remove,
    get,
  };
}

export function involveAsia() {
  const set = (payload: { clickId?: string }) => {
    const { clickId } = payload;
    if (clickId) {
      const storedClickId = getFromUserStorage(INVOLVE_ASIA_CLICK_ID);
      if (storedClickId != clickId) {
        saveToUserStorage(INVOLVE_ASIA_CLICK_ID, clickId, {
          expires: 30,
          domain: ".hungryhub.com",
        });
      }
    } else {
      removeFromUserStorage(INVOLVE_ASIA_CLICK_ID);
    }
  };

  const remove = () => {
    getAndRemove(INVOLVE_ASIA_CLICK_ID);
  };

  const get = () => {
    return getFromUserStorage(INVOLVE_ASIA_CLICK_ID);
  };

  return {
    remove,
    set,
    get,
  };
}

export function googleReserve() {
  const set = (payload: { rwgToken?: string }) => {
    const { rwgToken } = payload;
    if (rwgToken) {
      saveToUserStorage(GOOGLE_RESERVE_REFERRER_KEY, rwgToken, {
        expires: 30,
        domain: ".hungryhub.com",
      });
    }
  };

  const remove = () => {
    getAndRemove(GOOGLE_RESERVE_REFERRER_KEY);
  };

  const get = () => {
    return getFromUserStorage(GOOGLE_RESERVE_REFERRER_KEY);
  };

  const report = () => {
    const partnerId = 20001920;
    const endpoint =
      process.env.VUE_APP_MODE === "production"
        ? `https://www.google.com/maps/conversion/collect`
        : "https://www.google.com/maps/conversion/debug/collect";
    const token = get();
    if (token) {
      fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({
          conversion_partner_id: partnerId,
          rwg_token: token,
        }),
      });
    }
  };

  return {
    set,
    remove,
    report,
    get,
  };
}

export function accessTrade() {
  const set = (payload: { clickId?: string }) => {
    const { clickId } = payload;
    if (clickId) {
      const storedClickId = getFromUserStorage(ACCESS_TRADE_CLICK_ID);
      if (storedClickId != clickId) {
        saveToUserStorage(ACCESS_TRADE_CLICK_ID, clickId, {
          expires: Date.now(),
          domain: ".hungryhub.com",
        });
      }
    } else {
      removeFromUserStorage(ACCESS_TRADE_CLICK_ID);
    }
  };

  const remove = () => {
    getAndRemove(ACCESS_TRADE_CLICK_ID);
  };

  const get = () => {
    return getFromUserStorage(ACCESS_TRADE_CLICK_ID);
  };

  return {
    remove,
    set,
    get,
  };
}
