import { isEmpty } from "lodash-es";

window.enableNetcore = true;

function toggleEnable(enable: boolean) {
  window.enableNetcore = enable;
}

function sendEvent(eventName: string, payload: Record<string, any>) {
  if (!window.enableNetcore || isEmpty(payload) || !window.smartech) return;
  window.smartech("dispatch", eventName, payload);
}

function updateUserProperties(
  userIdenttity: string,
  properties: Record<string, any>
) {
  if (!window.enableNetcore || !window.smartech) return;
  setUserIdentity(userIdenttity);
  window.smartech("contact", "LIST IDENTIFIER", properties);
}

function setUserIdentity(userIdenttity: string) {
  if (!window.enableNetcore || !window.smartech) return;
  window.smartech("identify", userIdenttity);
}

export { toggleEnable, sendEvent, updateUserProperties, setUserIdentity };
