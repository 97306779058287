<template>
  <div
    id="navBar"
    class="sticky w-full mx-auto bg-white navbar-wrapper"
    :class="[
      !openendModals ? ' z-48' : null,
      isDesktop ? 'p-1' : null,
      isShowSelectCity && isDesktop ? 'py-3' : null,
    ]"
    style="top: 0"
  >
    <OfflineBanner />
    <NewVersionBanner />
    <div
      class="flex items-center max-width hh-black lg:px-2"
      :class="isDesktop ? 'relative' : null"
    >
      <span class="relative py-3 pl-2 pr-3 menu-dropdown-wrapper">
        <div
          v-if="isMobile || isTablet || isDesktop"
          class="h-[20px] w-[20px] navbar-mobile"
          @click.prevent="openMenu"
        >
          <HhImage
            :img="iconDropdown"
            :fallback="iconDropdown"
            is-local-image
            class="cursor-pointer h-[20px] w-[20px]"
          />
        </div>
        <div
          v-if="openDropdown"
          class="fixed"
          :class="isMobile ? 'bg-modal-dropdown-mobile' : 'bg-modal-dropdown'"
        >
          <div class="absolute hidden menu-dropdown-content">
            <ul class="px-4 text-sm whitespace-nowrap">
              <li class="py-2 hover:font-black">
                <a
                  href="http://blog.hungryhub.com/hungryhub-for-business-eng/"
                  rel="noopener noreferrer"
                  >{{ $t("forRestaurant") }}</a
                >
              </li>
              <li class="py-2 hover:font-black">
                <a href="https://blog.hungryhub.com" rel="noopener noreferrer">
                  {{ $t("blog") }}
                </a>
              </li>
              <li class="pt-2 pb-3 border-b border-gray-400 hover:font-black">
                <router-link :to="{ name: ROUTE_VOUCHER_PAGE }">
                  {{ $t("giftCard") }}
                </router-link>
              </li>
              <li class="py-2 hover:font-black">
                <a href="https://hungryhub.zendesk.com/hc/en-us">
                  {{ $t("help") }}</a
                >
              </li>
              <li class="py-2">
                {{ $t("downloadApp") }}
                <a
                  href="https://play.google.com/store/apps/details?id=com.hb.hungryhub"
                  rel="noopener noreferrer"
                >
                  <HhImage
                    :img="'@/assets/google-play-logo.jpg'"
                    :fallback="'@/assets/google-play-logo.jpg'"
                    is-local-image
                    style="width: 130px"
                    class="mt-4"
                  />
                </a>
                <a
                  href="https://apps.apple.com/th/app/hungry-hub-dining-offer-app/id879303325"
                  rel="noopener noreferrer"
                >
                  <HhImage
                    :img="'@/assets/app-store-logo.jpg'"
                    :fallback="'@/assets/app-store-logo.jpg'"
                    is-local-image
                    style="width: 130px"
                    class="mt-2"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </span>
      <router-link
        :id="isDesktop ? 'logo-hh' : null"
        :to="{ name: ROUTE_HOME_PAGE }"
        :class="isDesktop && !isShowSelectCity ? 'absolute' : null"
        :style="isDesktop && !isShowSelectCity ? 'top: -18px;' : null"
      >
        <img
          :class="isMobile ? 'logo-mobile' : 'logo'"
          :src="logo"
          loading="lazy"
          alt="hungryhub logo"
          width="90"
          height="50"
        />
      </router-link>
      <div class="flex-auto"></div>
      <!-- desktop navigation -->
      <template v-if="isDesktop">
        <div v-if="isShowSelectCity" class="navbar-desktop">
          <TopBarHomePage />
        </div>
        <div v-if="!isSearchPage && !isShowSelectCity" class="flex-1 h-8">
          <RestaurantSearchSuggestion @on-result-clicked="resetSearchState">
            <template #default="{ handler }">
              <form
                class="flex searchbox-desktop"
                :class="[
                  isSearchBoxOpen ? 'searchbox-open' : '',
                  isShowFavButton ? 'with-fav-button' : '',
                ]"
                @submit.prevent="submitSearchForm"
              >
                <router-link
                  class="flex-shrink-0"
                  :class="!isShowFavButton ? ' hidden' : '  inline-block'"
                  :to="{ name: 'ProfileFavourite' }"
                >
                  <img
                    src="@/assets/icon-heart-red.png"
                    alt="icon favourite"
                    style="width: 28px"
                    loading="lazy"
                  />
                </router-link>

                <div class="flex w-full">
                  <input
                    v-model="query"
                    type="search"
                    :placeholder="$t('searchRestaurantPlaceHolder')"
                    name="search"
                    class="pb-2 truncate searchbox-input"
                    style="border-radius: 0; border-bottom: 1px solid #ababab"
                    required
                    @input="handler.inputHandler"
                    @focus="handler.focusHandler"
                  />
                  <input type="submit" class="rounded-full searchbox-submit" />
                  <img
                    class="inline searchbox-icon"
                    src="@/assets/icon-search-red-circle.png"
                    width="18"
                    height="18"
                    loading="lazy"
                    alt="search icon"
                    @click="isSearchBoxOpen = !isSearchBoxOpen"
                  />
                </div>
              </form>
            </template>
          </RestaurantSearchSuggestion>
        </div>
        <div class="username-section">
          <template v-if="isLoading">
            <div
              class="p-0 mx-2 my-0 overflow-hidden ph-item"
              style="width: 120px; height: 20px"
            >
              <div class="p-0 ph-col-12">
                <div class="ph-picture"></div>
              </div>
            </div>
          </template>
          <template v-else>
            <router-link
              v-if="isUserSignedIn"
              :to="{ name: ROUTE_PROFILE_HOME }"
              class="px-4 py-2 border-none cursor-pointer"
            >
              {{ name }}
            </router-link>
            <template v-else>
              <span class="px-4 py-3">
                <button
                  id="navbar-register-button"
                  class="text-sm font-black bg-white cursor-pointer"
                  @click="$emit('on-register-clicked')"
                >
                  {{ $t("join") }}
                </button>
              </span>
              <span class="py-3">
                <button
                  id="navbar-login-button"
                  class="px-5 py-1 text-sm font-bold text-white border rounded-lg cursor-pointer bg-red-dark border-red-dark"
                  @click="$emit('on-signin-clicked')"
                >
                  {{ $t("signIn") }}
                </button>
              </span>
            </template>
          </template>
        </div>
        <span v-if="isUserSignedIn" class="relative ml-5 mr-4">
          <a class="cursor-pointer text-red-dark" @click.prevent="doSignOut">{{
            $t("signOut")
          }}</a>
        </span>
        <div v-if="isShowSelectCity" class="relative">
          <button
            class="relative flex items-center px-2 text-sm font-bold border-none cursor-pointer lg:px-4"
            @click="toggleDropdown('lang')"
          >
            <div
              class="flex items-center justify-center mr-1 text-black uppercase"
            >
              <img :src="showFlagLanguange" class="mr-1" style="width: 28px" />
              {{ lang }}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              fill="currentColor"
              class="inline-block transition-all icon-chevron-down"
              :class="showDropdownLang ? 'rotate-180' : ''"
              viewBox="0 0 16 16"
              stroke="currentColor"
            >
              <path
                fill-rule="evenodd"
                stroke-width="2"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </button>
          <div v-if="showDropdownLang" class="absolute right-0 z-50 shadow-md">
            <div
              class="text-xs rounded-lg"
              style="width: 85px; background: #fff"
            >
              <div
                class="flex items-center px-3 pt-3 pb-2 uppercase rounded-lg cursor-pointer border-dropdown"
                @click="changeLang(langNotSelect)"
              >
                <img
                  :src="listDropdownLang.icon"
                  alt="flag"
                  class="mr-1"
                  style="width: 24px"
                />
                {{ listDropdownLang.title }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- mobile navigation -->
      <template v-else>
        <!-- search bar -->
        <div
          v-if="!isSearchPage"
          class="flex items-center justify-center font-bold"
        >
          <router-link
            class="flex-shrink-0"
            :class="!isShowFavButton ? ' hidden' : '  inline-block'"
            :to="{ name: 'ProfileFavourite' }"
          >
            <img
              src="@/assets/icon-heart-red.png"
              alt="icon favourite"
              class="w-[25px] h-[25px]"
              loading="lazy"
            />
          </router-link>
        </div>
        <!-- login / username section -->
        <div v-if="isMobile" class="flex items-center justify-center mr-2">
          <template v-if="isLoading">
            <div
              class="p-0 mx-2 my-0 overflow-hidden ph-item"
              style="width: 120px; height: 20px"
            >
              <div class="p-0 ph-col-12">
                <div class="ph-picture"></div>
              </div>
            </div>
          </template>
          <template v-else>
            <HHDropdown v-if="isUserSignedIn" id="username-dropdown">
              <template #activator="{ toggle }">
                <div
                  class="px-2 py-2 text-sm bg-white border-none cursor-pointer lg:px-4"
                  @click="toggle"
                >
                  <HHTextTruncate
                    :text="name"
                    :max-length="10"
                    :can-read-more="false"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    fill="currentColor"
                    class="inline-block icon-chevron-down text-red-dark"
                    viewBox="0 0 16 16"
                    stroke="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      stroke-width="2"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </div>
              </template>
              <template #content>
                <div
                  class="px-4 text-xs bg-white rounded-lg border-dropdown"
                  style="width: 100px"
                >
                  <router-link
                    class="block pt-3 pb-2"
                    :to="{ name: ROUTE_PROFILE_HOME }"
                  >
                    Profile
                  </router-link>
                  <button
                    class="block w-full pt-2 pb-3 text-left"
                    @click="doSignOut"
                  >
                    Sign Out
                  </button>
                </div>
              </template>
            </HHDropdown>
            <template v-else>
              <span class="py-3 pl-1">
                <button
                  id="navbar-register-button"
                  class="px-4 text-sm font-bold cursor-pointer"
                  @click="$emit('on-register-clicked')"
                >
                  {{ $t("join") }}
                </button>
              </span>
              <span class="py-3 pr-3">
                <button
                  id="navbar-login-button"
                  class="px-3 py-1 text-sm font-bold text-white border rounded-lg cursor-pointer bg-red-dark border-red-dark"
                  @click="$emit('on-signin-clicked')"
                >
                  {{ $t("signIn") }}
                </button>
              </span>
            </template>

            <span class="relative">
              <button
                class="relative flex items-center py-3 text-sm border-none cursor-pointer lg:px-4"
                @click="toggleDropdown"
              >
                <div
                  class="flex items-center justify-center mr-2 font-bold uppercase"
                >
                  <img
                    :src="showFlagLanguange"
                    alt="flag"
                    style="width: 16px"
                    class="flex-shrink-0 mr-1"
                  />
                  {{ lang }}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  fill="currentColor"
                  class="inline-block transition-all icon-chevron-down text-dark-grey"
                  :class="showDropdownLang ? 'rotate-180' : ''"
                  viewBox="0 0 16 16"
                  stroke="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    stroke-width="2"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </button>
              <div
                v-if="showDropdownLang"
                class="absolute right-0 z-50 shadow-md top-[80%]"
              >
                <div
                  class="text-xs bg-white rounded-lg border-dropdown"
                  style="width: 63px"
                >
                  <div
                    class="flex items-center px-1 py-2 font-bold uppercase cursor-pointer"
                    @click="changeLang(langNotSelect)"
                  >
                    <img
                      :src="listDropdownLang.icon"
                      alt="flag"
                      class="flex-shrink-0 mr-1"
                      style="width: 16px"
                    />
                    {{ listDropdownLang.title }}
                  </div>
                </div>
              </div>
            </span>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import TopBarHomePage from "@/components/TopBarHomePage.vue";
import { ref } from "@vue/composition-api";
import { mapState, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import {
  selectedCityId,
  availableCities,
  isLoading as isConfigLoading,
} from "@/composable/selectCity";
import queryString from "query-string";
import RestaurantSearchSuggestion from "@/components/Shared/RestaurantSearchSuggestion";
import OfflineBanner from "@/components/Common/OfflineBanner";
import NewVersionBanner from "@/components/Common/NewVersionBanner.vue";
import HHDropdown from "@/components/Shared/HHDropdown";
import {
  ROUTE_HOME_PAGE,
  ROUTE_RESTAURANT_PAGE,
  ROUTE_PROFILE_HOME,
  ROUTE_VOUCHER_PAGE,
  ROUTE_SEARCH_PAGE,
  PACKAGE_CODE_HAH,
  CITY_NAME_SINGAPORE,
} from "@/lib/constant";
import { state as favState } from "@/composable/favouriteRestaurant";
import enFlag from "@/assets/flag-en.png";
import thFlag from "@/assets/flag-th-new.png";

export default {
  name: "NavBar",
  components: {
    RestaurantSearchSuggestion,
    OfflineBanner,
    HHDropdown,
    NewVersionBanner,
    TopBarHomePage,
  },
  setup() {
    const { favouriteRestaurantIds } = favState;
    let openDropdown = ref(false);
    let showDropdownLang = ref(false);

    return {
      selectedCityId,
      availableCities,
      isConfigLoading,
      favouriteRestaurantIds,
      ROUTE_VOUCHER_PAGE,
      ROUTE_PROFILE_HOME,
      ROUTE_HOME_PAGE,
      ROUTE_SEARCH_PAGE,
      openDropdown,
      showDropdownLang,
    };
  },
  data() {
    return {
      query: "",
      isSearchBoxOpen: false,
    };
  },
  computed: {
    ...mapState(["lang", "baseUrl"]),
    ...mapState("user", ["name", "isLoading"]),
    ...mapGetters(["baseUrlWithLang"]),
    ...mapGetters("user", ["isUserSignedIn"]),
    ...mapFields("search", [
      "searchByCityId",
      "selectedPackageType",
      "isDelivery",
    ]),
    isShowFavButton() {
      return (
        this.favouriteRestaurantIds &&
        this.favouriteRestaurantIds.length >= 3 &&
        this.$route.path.includes("/profile") === false
      );
    },
    isSearchPage() {
      return this.$route.name === ROUTE_SEARCH_PAGE;
    },
    isShowSelectCity() {
      return this.$route.name === ROUTE_HOME_PAGE;
    },
    logo() {
      return this.isDesktop || this.isTablet
        ? require("@/assets/logo-new-full.png")
        : require("@/assets/logo-new-full-alternate.png");
    },
    openendModals() {
      return this.$vfm.openedModals.length;
    },
    showFlagLanguange() {
      let flagIcon = "";
      if (this.lang === "th") {
        flagIcon = thFlag;
      } else if (this.lang === "en") {
        flagIcon = enFlag;
      }
      return flagIcon;
    },
    listDropdownLang() {
      let lang = {};
      if (this.lang === "th") {
        lang = {
          icon: enFlag,
          title: "EN",
        };
      } else if (this.lang === "en") {
        lang = {
          icon: thFlag,
          title: "TH",
        };
      }
      return lang;
    },
    iconDropdown() {
      if (this.openDropdown) {
        return "@/assets/icon-close-soft.png";
      }

      return "@/assets/hamburger.png";
    },
  },
  mounted() {
    if (this.isDesktop) {
      this.onScrollNavbar();
    }
    this.defaultLang();
  },
  methods: {
    resetSearchState() {
      this.query = "";
      this.isSearchBoxOpen = false;
    },
    selectCity(event) {
      this.selectedCityId = event.target.value;
    },
    submitSearchForm() {
      if (this.query.length > 0) {
        window.location = `/restaurants/search?name_like=${this.query}&locale=${this.lang}`;
      }
    },
    async changeLang(lang) {
      if (this.lang !== lang) {
        const allowChangeLang = await this.$store.dispatch("changeLang", lang);

        if (allowChangeLang) {
          if (this.isUserSignedIn) {
            await this.$store.dispatch("user/updateProfile", { lang: lang });
            if (
              window.clevertap &&
              typeof window.clevertap.setMultiValuesForKey === "function"
            ) {
              window.clevertap.setMultiValuesForKey("language", lang);
            }
          }
          this.$i18nInstance.locale = lang;
          this.$dayjs.locale(lang);
          this.$veeValidateLocalize(lang);
          const parsedQuery = queryString.parse(window.location.search);
          parsedQuery.locale = lang;
          const queryStringify = queryString.stringify(parsedQuery);
          window.location.search = queryStringify;
        }
      }
    },
    doSignOut() {
      this.$store.dispatch("user/doSignOut");
      this.$alert.success("Signed Out Successfully");
      window.location.href = this.$router.resolve({
        name: ROUTE_RESTAURANT_PAGE,
      }).href;
    },
    openMenu() {
      this.openDropdown = !this.openDropdown;
      const el = document.body;
      if (this.openDropdown) {
        el.classList.add("fixed");
      } else {
        el.classList.remove("fixed");
      }
    },
    toggleDropdown() {
      this.showDropdownLang = !this.showDropdownLang;
    },
    defaultLang() {
      if (this.lang === "en") {
        this.langNotSelect = "th";
      } else if (this.lang === "th") {
        this.langNotSelect = "en";
      }
    },
    onScrollNavbar() {
      window.document.onscroll = () => {
        const navBar = document.getElementById("navBar");
        const logoHH = document.getElementById("logo-hh");
        if (window.scrollY > navBar.offsetHeight) {
          logoHH.classList.remove("absolute");
          logoHH.style.top = null;
          navBar.classList.add("shadow");
        } else if (!this.isShowSelectCity) {
          logoHH.classList.add("absolute");
          logoHH.style.top = "-18px";
          navBar.classList.remove("shadow");
        }
      };
    },
    resetFilter() {
      availableCities.value.map((item) => {
        if (parseInt(item.id) === parseInt(this.searchByCityId)) {
          if (item.name === CITY_NAME_SINGAPORE) {
            if (Array.isArray(this.selectedPackageType)) {
              this.selectedPackageType = this.selectedPackageType.filter(
                (item) => {
                  return item !== PACKAGE_CODE_HAH;
                }
              );
              this.isDelivery = false;
            }
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
// custom tippy theme
.tippy-box[data-theme~="navbar-tooltip"] {
  background-color: #ebeaea;
  @apply text-sm text-red-dark;
}
.menu-dropdown-content {
  background: #ebeaea;
  // @apply absolute hidden;
  @apply block;
  top: 0px;
  left: 0px;
}

.bg-modal-dropdown {
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  margin-top: 30px;

  @media (max-width: 767px) {
    margin-top: 22px;
  }
}

.bg-modal-dropdown-mobile {
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  margin-top: 12px;

  @media (min-width: 768px) {
    margin-top: 20px;
  }
}

.border-dropdown {
  border: 1px solid #f6f6f6;
}

.navbar-desktop {
  @media (max-width: 767px) {
    display: none;
  }
}
.navbar-mobile {
  display: none !important;
  @media (max-width: 767px) {
    display: block !important;
  }
}
</style>
<i18n>
{
  "en": {
    "seeAllRestaurant": "See All Restaurants",
    "allDineInRestaurant": "All Dine In Restaurants",
    "allDeliveryRestaurant": "All Delivery Restaurants",
    "support": "Support",
    "groupDining": "Group Dining",
    "downloadApp": "Download App",
    "promotion": "Promotions",
    "giftCard": "Gift Card",
    "forRestaurant": "For Restaurant",
    "forRestaurants": "For Restaurants",
    "signIn": "Sign In",
    "register": "Register",
    "signOut": "Sign out",
    "signInRegister": "Sign In / Register",
    "blog": "Blog",
    "help" : "Help",
    "language" : "Language",
    "join" : "Join"
  },
  "th": {
    "seeAllRestaurant": "ดูร้านอาหารทั้งหมด",
    "allDineInRestaurant": "รวมร้านอาหารทั้งหมด",
    "allDeliveryRestaurant": "รวมร้านที่ส่งเดลิเวอรี่",
    "support": "ช่วยเหลือ",
    "groupDining": "จองโต๊ะกลุ่มใหญ่",
    "downloadApp": "ดาวน์โหลดแอปฯ",
    "promotion": "โปรโมชั่น",
    "giftCard": "กิ๊ฟการ์ด",
    "forRestaurant": "สำหรับร้านอาหาร",
    "forRestaurants": "ร้านอาหารสนใจเข้าร่วม",
    "signIn": "เข้าสู่ระบบ",
    "register": "ลงทะเบียน",
    "signOut": "ออกจากระบบ",
    "signInRegister": "เข้าสู่ระบบ / ลงทะเบียน",
    "blog": "บล็อก",
    "help": "ช่วยเหลือ",
    "language" : "ภาษา",
    "join" : "ลงทะเบียน"
  }
}
</i18n>
