<template>
  <div :key="keyId" class="relative lg:mx-0">
    <RestaurantSearch>
      <template #input-view="{ handler, meta }">
        <slot :handler="handler" :meta="meta"></slot>
      </template>
      <template #suggestion-view="{ suggestions, handler, meta }">
        <!-- search suggestion loading -->
        <template v-if="meta.isShowSuggestion">
          <div
            id="search-loading"
            class="items-center search-body"
            :class="meta.isLoading ? ' flex' : ' hidden'"
          >
            <div class="mr-2 loader small"></div>
            <span>Loading</span>
          </div>
          <!-- search suggestion result -->
          <div v-show="!meta.isLoading">
            <div v-show="!meta.isEmptyForm">
              <div v-show="!meta.isSuccess" class="search-body">
                Oops, something went wrong
              </div>
            </div>
            <div
              v-show="meta.isSuccess && !meta.isAnyResult"
              class="search-body"
            >
              No result found
            </div>

            <div
              v-show="meta.isSuccess && meta.isAnyResult"
              id="search-suggestion"
            >
              <div class="sections">
                <!-- tags result -->
                <div v-for="(suggestion, idx) in suggestions" :key="idx">
                  <div
                    v-show="
                      suggestion.data.length && suggestion.name !== 'restaurant'
                    "
                    class="section location-section"
                  >
                    <div class="capitalize section-heading">
                      <img
                        :src="getIconSrc(suggestion.name)"
                        alt="icon suggestion"
                        loading="lazy"
                        class="hh-icon"
                      />
                      <p>{{ $t(suggestion.name) }}</p>
                    </div>

                    <div class="section-body">
                      <div
                        v-for="(item, index) in suggestion.data"
                        :key="item.id"
                      >
                        <template v-if="index <= 3">
                          <a
                            class="block section-item hover:bg-gray-100"
                            @click.prevent="
                              goToSearchPage(suggestion.name, item)
                            "
                          >
                            {{ item.name }}
                          </a>
                        </template>
                        <template v-else>
                          <button
                            v-if="index === 4 && !suggestion.isShowAll"
                            class="view-more-btn hover:bg-gray-100"
                            @click="handler.toggleHiddenResult(suggestion.name)"
                          >
                            View More
                          </button>
                          <a
                            class="block section-item hover:bg-gray-100"
                            :class="!suggestion.isShowAll ? 'hidden' : 'block'"
                            @click.prevent="
                              goToSearchPage(suggestion.name, item)
                            "
                          >
                            {{ item.name }}
                          </a>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- restaurant result -->
                <div
                  v-show="suggestions.restaurantsSuggestions.data.length"
                  class="section restaurant-section"
                >
                  <div class="section-heading">
                    <img
                      src="@/assets/icon-home-red.png"
                      loading="lazy"
                      alt="icon restaurant"
                      class="hh-icon"
                    />
                    <p>Restaurant</p>
                  </div>
                  <div class="section-body">
                    <div
                      v-for="(restaurant, index) in suggestions
                        .restaurantsSuggestions.data"
                      :key="restaurant.id"
                    >
                      <template v-if="index <= 3">
                        <a
                          :href="restaurantLink(restaurant.slug)"
                          class="flex section-item hover:bg-gray-100"
                          @click.prevent="goToRestaurantPage(restaurant.slug)"
                        >
                          <HhImage
                            :img="restaurant.coverImage"
                            :mobile-width="55"
                            :mobile-height="55"
                            :desktop-width="55"
                            :desktop-height="55"
                            class="bg-gray-300"
                          />
                          <div>
                            <div class="flex items-center">
                              <template v-if="restaurant.isAds">
                                <div class="flex-shrink-0 mr-1 text-red-dark">
                                  Ad
                                </div>
                                <div
                                  class="w-2 h-2 mr-1 rounded-full bg-red-dark"
                                ></div>
                                <div>{{ restaurant.name }}</div>
                              </template>
                              <template v-else>
                                {{ restaurant.name }}
                              </template>
                            </div>
                            <div class="mt-1">{{ restaurant.location }}</div>
                          </div>
                        </a>
                      </template>
                      <template v-else>
                        <button
                          v-if="
                            index === 4 &&
                            !suggestions.restaurantsSuggestions.isShowAll
                          "
                          class="view-more-btn hover:bg-gray-100"
                          @click="handler.toggleHiddenResult('restaurant')"
                        >
                          View More
                        </button>
                        <a
                          :href="restaurantLink(restaurant.slug)"
                          class="flex section-item hover:bg-gray-100"
                          :class="
                            !suggestions.restaurantsSuggestions.isShowAll
                              ? 'hidden'
                              : 'block'
                          "
                          @click.prevent="goToRestaurantPage(restaurant.slug)"
                        >
                          <HhImage
                            :img="restaurant.coverImage"
                            :mobile-width="55"
                            :mobile-height="55"
                            :desktop-width="55"
                            :desktop-height="55"
                            class="bg-gray-300"
                          />
                          <div>
                            <div>{{ restaurant.name }}</div>
                            <div class="mt-1">{{ restaurant.location }}</div>
                          </div>
                        </a>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </RestaurantSearch>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { nanoid } from "nanoid";
import useLazyImport from "@/composable/useLazyImport";
export default {
  components: {
    RestaurantSearch: () =>
      useLazyImport(() =>
        import(
          "@/components/Shared/Renderless/RestaurantSearch/RestaurantSearch"
        )
      ),
  },
  data() {
    return {
      keyId: nanoid(3),
    };
  },
  computed: {
    ...mapGetters(["baseUrlWithLang"]),
  },
  methods: {
    restaurantLink(slug) {
      return `/restaurants/${slug}`;
    },
    goToRestaurantPage(slug) {
      const newRestaurant = this.$router.resolve({
        name: "RestaurantPage",
        params: { restaurantName: slug },
      });
      window.location = newRestaurant.href;
      this.onResulClicked();
    },
    getIconSrc(name) {
      return name === "location" ||
        name === "mrtRoute" ||
        name === "btsRoute" ||
        name === "shoppingMall"
        ? require("@/assets/icon-location-red.png")
        : require("@/assets/icon-course-red.png");
    },
    cuisineLink(data) {
      return `/restaurants/search?cuisine_id[]=${data.id}&cuisine_name[]=${data.name}`;
    },
    diningStyleLink(data) {
      return `/restaurants/search?dining_style_id[]=${data.id}&dining_style_name[]=${data.name}`;
    },
    locationLink(data) {
      return `/restaurants/search?location_id[]=${data.id}&location_name=${data.name}&locationTotalRestaurant[]=${data.totalRestaurants}`;
    },
    goToSearchPage(type, data) {
      let path = ``;
      if (type === "cuisine") {
        path = `/restaurants/search?cuisine_id[]=${data.id}&cuisine_name[]=${data.name}`;
      } else if (type === "location") {
        path = `/restaurants/search?location_id[]=${data.id}&location_name[]=${data.name}&location_total_restaurant[]=${data.totalRestaurants}`;
      } else if (type === "diningStyle") {
        path = `/restaurants/search?dining_style_id[]=${data.id}&dining_style_name[]=${data.name}`;
      } else if (type === "facility") {
        path = `/restaurants/search?tag_id[]=${data.id}&tag_name[]=${data.name}`;
      } else if (type === "mrtRoute") {
        path = `/restaurants/search?location_mrt_route_id[]=${data.id}&location_mrt_route_name[]=${data.name}&location_mrt_route_total_restaurant[]=${data.totalRestaurants}`;
      } else if (type === "shoppingMall") {
        path = `/restaurants/search?location_shopping_mall_id[]=${data.id}&location_shopping_mall_name[]=${data.name}&location_shopping_mall_total_restaurant[]=${data.totalRestaurants}`;
      } else if (type === "btsRoute") {
        path = `/restaurants/search?location_bts_route_id[]=${data.id}&location_bts_route_name[]=${data.name}&location_bts_route_total_restaurant[]=${data.totalRestaurants}`;
      } else {
        path = `/restaurants/search?${type}[]=${data.id}`;
      }
      const newRoute = this.$router.resolve({
        path,
      });
      this.$routerPushGuard(newRoute);
      this.onResulClicked();
    },
    onResulClicked() {
      this.$emit("on-result-clicked");
      this.reRender();
    },
    reRender() {
      this.keyId = nanoid(3);
    },
  },
};
</script>

<style lang="scss">
.search-body {
  border: none;
  background: transparent;

  @apply absolute z-10 w-full bg-white text-red-dark py-2 px-4 text-left;

  border-radius: 10px;
  box-shadow: -1px 2px 25px rgba(0, 0, 0, 0.16);
}

#search-suggestion {
  max-height: 100vh;
  overflow-y: scroll;
  box-shadow: -1px 2px 25px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  @apply bg-white  border text-left px-3 py-6 absolute w-full z-10;

  .view-more-btn {
    @apply w-full py-2 text-left font-black  text-red-dark;
  }

  .sections {
    @apply mx-4;
  }

  .section-heading {
    @apply flex text-black;

    img {
      width: 18px;
      height: 18px;

      @apply mr-2;
    }
  }

  .section-body {
    @apply text-sm text-gray-700 py-2 overflow-hidden;

    div {
      @apply truncate;
    }

    img {
      width: 55px;
      height: 55px;

      @apply mr-2;
    }

    .section-item {
      @apply py-1 cursor-pointer w-full;
    }
  }

  .section.cuisine-section .section-body,
  .section.location-section .section-body {
    margin-left: 26px;
  }
}
</style>
<i18n>
{
  "en": {
    "facility": "Facility",
    "mrtRoute": "MRT Route",
    "shoppingMall": "Shopping Mall",
    "btsRoute": "BTS Route"
  },
  "th": {
    "facility": "สิ่งอำนวยความสะดวก",
    "mrtRoute": "เส้นทาง MRT",
    "shoppingMall": "ห้างสรรพสินค้า",
    "btsRoute": "เส้นทาง BTS"
  }
}
</i18n>
