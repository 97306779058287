var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.keyId,staticClass:"relative lg:mx-0"},[_c('RestaurantSearch',{scopedSlots:_vm._u([{key:"input-view",fn:function(ref){
var handler = ref.handler;
var meta = ref.meta;
return [_vm._t("default",null,{"handler":handler,"meta":meta})]}},{key:"suggestion-view",fn:function(ref){
var suggestions = ref.suggestions;
var handler = ref.handler;
var meta = ref.meta;
return [(meta.isShowSuggestion)?[_c('div',{staticClass:"items-center search-body",class:meta.isLoading ? ' flex' : ' hidden',attrs:{"id":"search-loading"}},[_c('div',{staticClass:"mr-2 loader small"}),_c('span',[_vm._v("Loading")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!meta.isLoading),expression:"!meta.isLoading"}]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!meta.isEmptyForm),expression:"!meta.isEmptyForm"}]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!meta.isSuccess),expression:"!meta.isSuccess"}],staticClass:"search-body"},[_vm._v(" Oops, something went wrong ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(meta.isSuccess && !meta.isAnyResult),expression:"meta.isSuccess && !meta.isAnyResult"}],staticClass:"search-body"},[_vm._v(" No result found ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(meta.isSuccess && meta.isAnyResult),expression:"meta.isSuccess && meta.isAnyResult"}],attrs:{"id":"search-suggestion"}},[_c('div',{staticClass:"sections"},[_vm._l((suggestions),function(suggestion,idx){return _c('div',{key:idx},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                    suggestion.data.length && suggestion.name !== 'restaurant'
                  ),expression:"\n                    suggestion.data.length && suggestion.name !== 'restaurant'\n                  "}],staticClass:"section location-section"},[_c('div',{staticClass:"capitalize section-heading"},[_c('img',{staticClass:"hh-icon",attrs:{"src":_vm.getIconSrc(suggestion.name),"alt":"icon suggestion","loading":"lazy"}}),_c('p',[_vm._v(_vm._s(_vm.$t(suggestion.name)))])]),_c('div',{staticClass:"section-body"},_vm._l((suggestion.data),function(item,index){return _c('div',{key:item.id},[(index <= 3)?[_c('a',{staticClass:"block section-item hover:bg-gray-100",on:{"click":function($event){$event.preventDefault();return _vm.goToSearchPage(suggestion.name, item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]:[(index === 4 && !suggestion.isShowAll)?_c('button',{staticClass:"view-more-btn hover:bg-gray-100",on:{"click":function($event){return handler.toggleHiddenResult(suggestion.name)}}},[_vm._v(" View More ")]):_vm._e(),_c('a',{staticClass:"block section-item hover:bg-gray-100",class:!suggestion.isShowAll ? 'hidden' : 'block',on:{"click":function($event){$event.preventDefault();return _vm.goToSearchPage(suggestion.name, item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]],2)}),0)])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(suggestions.restaurantsSuggestions.data.length),expression:"suggestions.restaurantsSuggestions.data.length"}],staticClass:"section restaurant-section"},[_c('div',{staticClass:"section-heading"},[_c('img',{staticClass:"hh-icon",attrs:{"src":require("@/assets/icon-home-red.png"),"loading":"lazy","alt":"icon restaurant"}}),_c('p',[_vm._v("Restaurant")])]),_c('div',{staticClass:"section-body"},_vm._l((suggestions
                      .restaurantsSuggestions.data),function(restaurant,index){return _c('div',{key:restaurant.id},[(index <= 3)?[_c('a',{staticClass:"flex section-item hover:bg-gray-100",attrs:{"href":_vm.restaurantLink(restaurant.slug)},on:{"click":function($event){$event.preventDefault();return _vm.goToRestaurantPage(restaurant.slug)}}},[_c('HhImage',{staticClass:"bg-gray-300",attrs:{"img":restaurant.coverImage,"mobile-width":55,"mobile-height":55,"desktop-width":55,"desktop-height":55}}),_c('div',[_c('div',{staticClass:"flex items-center"},[(restaurant.isAds)?[_c('div',{staticClass:"flex-shrink-0 mr-1 text-red-dark"},[_vm._v(" Ad ")]),_c('div',{staticClass:"w-2 h-2 mr-1 rounded-full bg-red-dark"}),_c('div',[_vm._v(_vm._s(restaurant.name))])]:[_vm._v(" "+_vm._s(restaurant.name)+" ")]],2),_c('div',{staticClass:"mt-1"},[_vm._v(_vm._s(restaurant.location))])])],1)]:[(
                          index === 4 &&
                          !suggestions.restaurantsSuggestions.isShowAll
                        )?_c('button',{staticClass:"view-more-btn hover:bg-gray-100",on:{"click":function($event){return handler.toggleHiddenResult('restaurant')}}},[_vm._v(" View More ")]):_vm._e(),_c('a',{staticClass:"flex section-item hover:bg-gray-100",class:!suggestions.restaurantsSuggestions.isShowAll
                            ? 'hidden'
                            : 'block',attrs:{"href":_vm.restaurantLink(restaurant.slug)},on:{"click":function($event){$event.preventDefault();return _vm.goToRestaurantPage(restaurant.slug)}}},[_c('HhImage',{staticClass:"bg-gray-300",attrs:{"img":restaurant.coverImage,"mobile-width":55,"mobile-height":55,"desktop-width":55,"desktop-height":55}}),_c('div',[_c('div',[_vm._v(_vm._s(restaurant.name))]),_c('div',{staticClass:"mt-1"},[_vm._v(_vm._s(restaurant.location))])])],1)]],2)}),0)])],2)])])]:_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }